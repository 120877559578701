import NextImage from 'next/image'
import styled, { css } from 'styled-components'

import { ClinicSearchHeroStoryblok, HeroStoryblok } from 'common/types'
import { getImageAttributes } from 'common/utils/content'

import { HeroVariants } from './CommonHero'

type Props = {
  block: HeroStoryblok | ClinicSearchHeroStoryblok
}

const renderImageVariant = ({
  variant,
  image,
}: HeroStoryblok | ClinicSearchHeroStoryblok) => {
  if (variant === 'side') {
    return (
      <NextImage
        priority
        width={720}
        height={720}
        alt={getImageAttributes(image).alt}
        src={getImageAttributes(image, '1200x0/smart/filters:format(webp)').src}
        style={{ objectFit: 'cover', height: '100%' }}
      />
    )
  }

  if (variant === 'background') {
    return (
      <NextImage
        fill
        priority
        src={getImageAttributes(image).src}
        alt={getImageAttributes(image).alt}
        style={{ objectFit: 'cover', height: '100%' }}
      />
    )
  }

  if (variant === 'center' || variant === 'center-inverted') {
    const { width, height, alt, src } = getImageAttributes(
      image,
      '2000x0/smart/filters:format(webp):quality(100)'
    )
    return (
      <NextImage
        priority
        alt={alt}
        src={src}
        width={width}
        height={height}
        style={{ objectFit: 'cover', height: '100%' }}
      />
    )
  }

  return (
    <NextImage
      width={720}
      height={720}
      alt={getImageAttributes(image).alt}
      src={getImageAttributes(image, '1200x0/smart/filters:format(webp)').src}
      style={{ objectFit: 'cover', height: '100%' }}
    />
  )
}

const ImageHero: React.FC<Props> = ({ block, ...props }) => {
  const { variant, image } = block
  return (
    <>
      {image && image.filename && (
        <ImageHolder variant={variant as HeroVariants} {...props}>
          {renderImageVariant(block)}
        </ImageHolder>
      )}
    </>
  )
}

const ImageHolder = styled.div<{ variant: HeroVariants }>`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xxl};

  ${({ variant }) => {
    const variants = {
      center: css`
        img {
          max-height: 40rem;
          width: 100%;
          height: 100%;

          object-fit: contain;
        }
      `,
      'center-inverted': css`
        img {
          max-height: 40rem;
          width: 100%;
          height: 100%;

          object-fit: contain;
        }
      `,
      side: css`
        ${({ theme }) => theme.media.md} {
          width: 50%;
        }

        img {
          object-fit: contain;
          max-height: 40rem;
          width: 100%;
          height: 100%;
          margin-left: auto;
        }
      `,
      background: css`
        position: absolute;

        width: 100%;
        max-width: unset;
        height: 100%;

        overflow: hidden;

        z-index: ${({ theme }) => theme.zIndex.low};

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      `,
    }

    return variants[variant || 'center']
  }}
`

export { ImageHero }
