import styled, { css } from 'styled-components'

import { ButtonGroup, Container, Tag, Text } from 'common/UI'
import { ClinicSearchHeroStoryblok, HeroStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'
import { CMSLink } from 'modules/shared'

export type HeroVariants = HeroStoryblok['variant']
type Props = {
  block: HeroStoryblok | ClinicSearchHeroStoryblok
}

export const CommonHero: React.FC<Props> = ({ block }) => {
  const {
    headline,
    tagline,
    primary_button_text,
    primary_button_link,
    secondary_button_text,
    secondary_button_link,
    tag_text,
  } = block

  const hasPrimaryButton = primary_button_text && primary_button_link
  const hasSecondaryButton = secondary_button_text && secondary_button_link

  return (
    <>
      {tag_text && <Tag label={tag_text} css={{ marginBottom: '1.5rem' }} />}
      <Headline as="h1" variant="display">
        {textByLine(headline, (part, i, length) => {
          return (
            <>
              {part}
              {i + 1 < length && <br />}
            </>
          )
        })}
      </Headline>
      {tagline &&
        textByLine(tagline, (line) => (
          <Tagline as="p" variant="title/small/regular">
            {line}
          </Tagline>
        ))}
      {(hasPrimaryButton || hasSecondaryButton) && (
        <ButtonsHolder inlineFrom="sm">
          {hasPrimaryButton && primary_button_link && (
            <CMSLink href={primary_button_link} variant="accent">
              {primary_button_text}
            </CMSLink>
          )}
          {hasSecondaryButton && secondary_button_link && (
            <CMSLink href={secondary_button_link} variant="ghost">
              {secondary_button_text}
            </CMSLink>
          )}
        </ButtonsHolder>
      )}
    </>
  )
}

const Headline = styled(Text)`
  &:last-of-type {
    padding-bottom: 1.25rem;
  }
`

const Tagline = styled(Text)`
  max-width: 41.875rem;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  opacity: 0.8;
`

const ButtonsHolder = styled(ButtonGroup)`
  padding-top: 2rem;
`

export const Wrapper = styled.section<{ variant: HeroVariants }>`
  position: relative;
  display: flex;
  overflow: hidden;

  ${({ variant }) => {
    const variants = {
      center: css`
        flex-direction: column;
        align-items: center;
        text-align: center;
      `,
      'center-inverted': css`
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
      `,
      side: css`
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        ${({ theme }) => theme.media.md} {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      `,
      background: css`
        flex-direction: column;

        ${({ theme }) => theme.media.md} {
          align-items: center;
          text-align: center;
        }
      `,
    }

    return variants[variant || 'center']
  }}
`

export const ContentHolder = styled(Container)<{ heroVariant: HeroVariants }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: inherit;

  width: 100%;
  max-width: 56.25rem;

  padding: 2rem 1rem;

  z-index: ${({ theme }) => theme.zIndex.low + 1};

  ${({ heroVariant }) =>
    heroVariant === 'side' &&
    css`
      max-width: 44rem;
      margin-right: 0;
      align-items: flex-start;

      ${({ theme }) => theme.media.md} {
        width: 50%;
      }
    `}

  ${({ theme }) => theme.media.md} {
    padding: 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 5rem;
  }
`
